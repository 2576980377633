import React from "react"
import marked from "marked"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PostPage = ({ post }) => {
  if (post.custom_template) {
    return (
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: marked(post.body),
        }}
      />
    )
  }
  return (
    <div
      className="body post-content"
      dangerouslySetInnerHTML={{
        __html: marked(post.body),
      }}
    />
  )
}
export default PostPage
